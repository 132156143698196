import gql from 'graphql-tag';

export const CompositeEntityNode = gql`
  query CompositeEntityNode($id: ID!) {
    CompositeEntityNode(id: $id) {
      graphTemplate
      displayNodeName
      order
      configObjectId
      addToParentDisplay
      addToParentGrid
      addToParentEditForm
      addToParentInsertForm
      accessibilityRegex
      editabilityRegex
      expressionAvailable
      name
      configObjectType
      itemDescription
      combinedNodes {
        showGrid
        configObjectId
        addToParentDisplay
        addToParentGrid
        addToParentEditForm
        addToParentInsertForm
        accessibilityRegex
        expressionAvailable
        displayNodeName
        graphTemplate
        entity {
          name
          configObjectId
          projectId
          configObjectType
          createdBy
          isDeleted
          itemDescription
          insert_ts
          updatedBy
          update_ts
          deletionDate
          dbTypeName
          supportedFlavor
          generateSkeleton
          logicalColumns {
            isPrimaryKey
            isDisplayColumn
            dataType
            isVerticalFieldKey
            dbCode
            length
            dbType
            isMandatory
            jsonName
            mode
            isUnique
            isDerived
            configObjectId
            name
            configObjectType
            projectId
            standardValidations {
              name
              configObjectId
              configObjectType
              mode
              defaultErrorMessage
              validationType
              regex
              isConditionAvailable
              conditionExpression
              conditionFields
              description
            }
          }
        }
        dataGrid {
          nestedComponentType
          subPortals {
            configObjectId
            name
            configObjectType
            portalType
            archived
            favorite
            modalRequired
            subPortalType
            portalDataSharing
            tabGroup
            order
            accessibilityRegex
            portalCards {
              editabilityRegex
              configObjectId
              name
              configObjectType
              label
              reloadRequired
              archived
              favorite
              x
              y
              w
              h
              order
              isStatic
              type
              uiComponentId
              isAccessibile
              accessibilityRegex
              referenceData
              initialComponent
              isFullScreenRequired
            }
          }
          configObjectId
          defaultOrdering
          gridType
          isHavingAdvanceFilterForm
          label
          swimlaneRequired
          modalRequired
          isRowReOrder
          isBorderEnable
          isStrippedEnable
          isHoverEnable
          isScrollEnable
          isServerPaginationEnable
          isRowSelectionEnable
          isHeaderAvailable
          isSubComponentEnable
          subComponentType
          isEditButtonEnable
          defaultSorting
          gridDataAlignment
          gridHeaderAlignment
          name
          configObjectType
          createdBy
          isDeleted
          insert_ts
          projectId
          itemDescription
          updatedBy
          update_ts
          deletionDate
          form {
            isRepeatable
            tabRequiredInFormsection
            tab
            order
            formType
            expressionAvailable
            accessibilityRegex
            editabilityRegex
            expressionFieldString
            formLabel
            defaultDataString
            maxRepeatation
            minRepeatation
            defaultRepeatation
            repeatationStyle
            isDeletionAllowed
            addAllowed
            filterFormReloadControl
            configObjectId
            name
            configObjectType
            createdBy
            isDeleted
            itemDescription
            insert_ts
            projectId
            updatedBy
            update_ts
            deletionDate
            logicalEntity {
              name
              configObjectId
              configObjectType
              createdBy
              isDeleted
              itemDescription
              insert_ts
              projectId
              updatedBy
              update_ts
              deletionDate
              dbTypeName
              supportedFlavor
              generateSkeleton
              logicalColumns {
                isPrimaryKey
                isDisplayColumn
                dataType
                isVerticalFieldKey
                dbCode
                length
                dbType
                isMandatory
                jsonName
                mode
                isUnique
                isDerived
                configObjectId
                name
                configObjectType
                createdBy
                isDeleted
                itemDescription
                insert_ts
                projectId
                updatedBy
                update_ts
                deletionDate
              }
            }
          }
          dataGridColumns {
            headerName
            order
            hyperlinkDBCode
            modalCss
            isDisplayDetail
            visible
            toolTip
            actionColumn
            actionColumnType
            group
            icon
            hyperLink
            hrefValue
            dateFormat
            hyperLink
            key
            expressionAvailable
            editabilityRegex
            width
            isEllipsesEnable
            isDefaultEditable
            isShowOnlyOnEdit
            isAuditColumnJson
            collapseIcon
            auditColumnName
            columnDataAlignment
            columnHeaderAlignment
            isTimeStamp
            datasourceId
            multivalueList
            selectItemsReferenceID
            fixColumn
            goToLink
            accessibilityRegex
            dbCode
            filterEnable
            filterType
            columnType
            isPrimaryKey
            configObjectId
            name
            configObjectType
            createdBy
            isDeleted
            itemDescription
            insert_ts
            projectId
            updatedBy
            update_ts
            deletionDate
            logicalColumn {
              isPrimaryKey
              isDisplayColumn
              dataType
              isVerticalFieldKey
              dbCode
              length
              dbType
              isMandatory
              jsonName
              mode
              isUnique
              isDerived
              configObjectId
              name
              configObjectType
              createdBy
              isDeleted
              itemDescription
              insert_ts
              projectId
              updatedBy
              update_ts
              deletionDate
            }
            formField {
              label
              order
              height
              type
              displayType
              editorType
              isMandatory
              isButtonTextBox
              expressionAvailable
              refreshFormOnChange
              accessibilityRegex
              editabilityRegex
              previewAccessibilityRegex
              previewEditabilityRegex
              deleteAccessibilityRegex
              deleteEditabilityRegex
              acceptedFileTypes
              maxFilesAllowed
              isReorderAllowed
              expressionFieldString
              configObjectId
              name
              configObjectType
              createdBy
              isDeleted
              itemDescription
              insert_ts
              projectId
              updatedBy
              update_ts
              deletionDate
              multivalueList
              selectItemsReferenceID
              isAsyncOption
              placeHolder
              dataSourceName
              defaultValue
              isMultiLingual
              formatDate
              isApplyDateFormat
              resetOnRefresh
              rowspan
              colspan
              hasToolTipIcon
              toolTipText
              isSearchBoxRequired
              isHyperlink
              gotoLink
              uploadLabel
              outDbcodeUploadFileTempId
              outDbcodeUploadFileName
              outDbcodeUploadFileType
              isRefreshFormWithUploadData
              valueType
              isReadOnly
              starCount
              starColor
              mask
              maskChar
              isSearchable
              isDisabled
              isMulti
              showYearDropdown
              showMonthDropdown
              maxDate
              minDate
              dateFormat
              dropdownMode
              timeFormat
              showTimeSelect
              showTimeSelectOnly
              timeIntervals
              timeCaption
              entityName
              parentDBCode
              layout
              dbCode
              fontSize
              hasSupportingLabel
              hasValueInSupportingLabel
              supportingLabel
              supportingLabelFontSize
              labelColor
            }
            logicalColumn {
              isPrimaryKey
              isDisplayColumn
              dataType
              isVerticalFieldKey
              dbCode
              length
              dbType
              isMandatory
              jsonName
              mode
              isUnique
              isDerived
              configObjectId
              name
              configObjectType
              createdBy
              isDeleted
              itemDescription
              insert_ts
              projectId
              updatedBy
              update_ts
              deletionDate
            }
          }
        }
        insertForm {
          defaultDataString
          isRepeatable
          addAllowed
          filterFormReloadControl
          isDeletionAllowed
          tabRequiredInFormsection
          columnDataPreprocessors {
            configObjectId
            name
            configObjectType
            preProcessorBean
            isMultiValue
            excecutionType
            jsCode
            createdBy
            isDeleted
            itemDescription
            insert_ts
            projectId
            updatedBy
            update_ts
            deletionDate
            actionFlows {
              configObjectId
              name
              configObjectType
            }
          }
          tab
          order
          formType
          buttonPanels {
            configObjectId
            defaultType
            buttonPanelPosition
            mode
            name
            projectId
            configObjectType
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
              createdBy
              isDeleted
              insert_ts
              updatedBy
              update_ts
              deletionDate
            }
            menuButtons {
              defaultType
              menuLabel
              position
              buttonClass
              warningMessage
              order
              accessibilityRegex
              editabilityRegex
              expressionAvailable
              transactionName
              modalRequired
              portalId
              configObjectId
              name
              configObjectType
              childRelations {
                relationType
                parentItemId
                childItemId
              }
              privileges {
                privilegeId
                privilegeType
                itemId
                roleId
              }

              buttons {
                label
                order
                buttonClass
                warningMessage
                portalId
                buttonAlignment
                accessibilityRegex
                editabilityRegex
                expressionAvailable
                configObjectId
                name
                configObjectType
                projectId
                modalCss
                modalRequired
                transactionName
                childRelations {
                  relationType
                  parentItemId
                  childItemId
                }
                privileges {
                  privilegeId
                  privilegeType
                  itemId
                  roleId
                }
              }
            }
            buttons {
              label
              order
              type
              dbCode
              toolTip
              buttonStyle
              icon
              modalRequired
              componentId
              buttonClass
              warningMessage
              portalId
              buttonAlignment
              accessibilityRegex
              editabilityRegex
              expressionAvailable
              configObjectId
              name
              configObjectType
              projectId
              modalCss
              transactionName
              childRelations {
                relationType
                parentItemId
                childItemId
              }
              privileges {
                privilegeId
                privilegeType
                itemId
                roleId
                createdBy
                isDeleted
                insert_ts
                updatedBy
                update_ts
                deletionDate
              }
            }
          }
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
            createdBy
            isDeleted
            insert_ts
            updatedBy
            update_ts
            deletionDate
          }
          expressionAvailable
          accessibilityRegex
          editabilityRegex
          formLabel
          maxRepeatation
          minRepeatation
          defaultRepeatation
          repeatationStyle
          isDeletionAllowed
          configObjectId
          name
          configObjectType
          projectId
          formSections {
            configObjectId
            headerLabel
            order
            displayName
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            tabGroup
            requiredFormfields
            componentsPerRow
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
              createdBy
              isDeleted
              insert_ts
              updatedBy
              update_ts
              deletionDate
            }
            name
            configObjectType
            projectId
            formFields {
              mandatoryIconAccessibilityRegex
              entityName
              parentDBCode
              layout
              dbCode
              label
              label
              colspan
              order
              height
              editorType
              defaultValue
              isButtonTextBox
              formatDate
              isApplyDateFormat
              columnDataPreprocessors {
                configObjectId
                name
                configObjectType
                preProcessorBean
                isMultiValue
                excecutionType
                jsCode
                createdBy
                isDeleted
                itemDescription
                insert_ts
                projectId
                updatedBy
                update_ts
                deletionDate
                actionFlows {
                  configObjectId
                  name
                  configObjectType
                }
              }
              type
              multivalueList
              resetOnRefresh
              isMandatory
              selectItemsReferenceID
              isAsyncOption
              placeHolder
              expressionAvailable
              refreshFormOnChange
              accessibilityRegex
              editabilityRegex
              previewAccessibilityRegex
              previewEditabilityRegex
              deleteAccessibilityRegex
              deleteEditabilityRegex
              acceptedFileTypes
              maxFilesAllowed
              isReorderAllowed
              configObjectId
              showYearDropdown
              showMonthDropdown
              maxDate
              minDate
              dropdownMode
              timeFormat
              showTimeSelect
              showTimeSelectOnly
              timeCaption
              timeIntervals
              isClearable
              privileges {
                privilegeId
                privilegeType
                itemId
                roleId
                createdBy
                isDeleted
                insert_ts
                updatedBy
                update_ts
                deletionDate
              }
              name
              configObjectType
              itemDescription
              projectId
              buttons {
                label
                order
                type
                dbCode
                toolTip
                buttonStyle
                icon
                modalRequired
                componentId
                buttonClass
                warningMessage
                portalId
                buttonAlignment
                accessibilityRegex
                editabilityRegex
                expressionAvailable
                configObjectId
                name
                configObjectType
                projectId
                modalCss
                transactionName
                childRelations {
                  relationType
                  parentItemId
                  childItemId
                }
                privileges {
                  privilegeId
                  privilegeType
                  itemId
                  roleId
                }
              }
              logicalColumn {
                isPrimaryKey
                dataType
                isVerticalFieldKey
                dbCode
                length
                dbType
                isMandatory
                jsonName
                mode
                isUnique
                isDerived
                configObjectId
                name
                configObjectType
                projectId
                isDisplayColumn
                standardValidations {
                  name
                  configObjectId
                  configObjectType
                  mode
                  defaultErrorMessage
                  validationType
                  regex
                  isConditionAvailable
                  conditionExpression
                  conditionFields
                  description
                }
              }
              fontSize
              hasSupportingLabel
              supportingLabel
              supportingLabelFontSize
              labelColor
            }
          }
        }
        editForm {
          defaultDataString
          isRepeatable
          addAllowed
          filterFormReloadControl
          isDeletionAllowed
          tabRequiredInFormsection
          columnDataPreprocessors {
            configObjectId
            name
            configObjectType
            preProcessorBean
            isMultiValue
            excecutionType
            jsCode
            createdBy
            isDeleted
            itemDescription
            insert_ts
            projectId
            updatedBy
            update_ts
            deletionDate
            actionFlows {
              configObjectId
              name
              configObjectType
            }
          }
          tab
          order
          formType
          expressionAvailable
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
            createdBy
            isDeleted
            insert_ts
            updatedBy
            update_ts
            deletionDate
          }
          accessibilityRegex
          editabilityRegex
          formLabel
          maxRepeatation
          minRepeatation
          defaultRepeatation
          repeatationStyle
          isDeletionAllowed
          configObjectId
          name
          configObjectType
          itemDescription
          projectId
          formSections {
            configObjectId
            headerLabel
            order
            displayName
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            tabGroup
            requiredFormfields
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
              createdBy
              isDeleted
              insert_ts
              updatedBy
              update_ts
              deletionDate
            }
            componentsPerRow
            name
            configObjectType
            itemDescription
            projectId
            formFields {
              mandatoryIconAccessibilityRegex
              height
              editorType
              entityName
              parentDBCode
              layout
              dbCode
              label
              label
              order
              colspan
              isButtonTextBox
              defaultValue
              type
              formatDate
              isApplyDateFormat
              columnDataPreprocessors {
                configObjectId
                name
                configObjectType
                preProcessorBean
                isMultiValue
                excecutionType
                jsCode
                createdBy
                isDeleted
                itemDescription
                insert_ts
                projectId
                updatedBy
                update_ts
                deletionDate
                actionFlows {
                  configObjectId
                  name
                  configObjectType
                }
              }
              multivalueList
              resetOnRefresh
              selectItemsReferenceID
              isAsyncOption
              placeHolder
              isMandatory
              expressionAvailable
              refreshFormOnChange
              showYearDropdown
              showMonthDropdown
              maxDate
              minDate
              dropdownMode
              timeFormat
              showTimeSelect
              showTimeSelectOnly
              timeCaption
              timeIntervals
              isClearable
              privileges {
                privilegeId
                privilegeType
                itemId
                roleId
                createdBy
                isDeleted
                insert_ts
                updatedBy
                update_ts
                deletionDate
              }
              accessibilityRegex
              editabilityRegex
              previewAccessibilityRegex
              previewEditabilityRegex
              deleteAccessibilityRegex
              deleteEditabilityRegex
              acceptedFileTypes
              maxFilesAllowed
              isReorderAllowed
              configObjectId
              name
              configObjectType
              itemDescription
              projectId
              buttons {
                label
                order
                type
                dbCode
                toolTip
                buttonStyle
                icon
                modalRequired
                componentId
                buttonClass
                warningMessage
                portalId
                buttonAlignment
                accessibilityRegex
                editabilityRegex
                expressionAvailable
                configObjectId
                name
                configObjectType
                projectId
                modalCss
                transactionName
                childRelations {
                  relationType
                  parentItemId
                  childItemId
                }
                privileges {
                  privilegeId
                  privilegeType
                  itemId
                  roleId
                }
              }
              logicalColumn {
                isPrimaryKey
                dataType
                isVerticalFieldKey
                dbCode
                length
                dbType
                isMandatory
                jsonName
                mode
                isUnique
                isDerived
                configObjectId
                name
                configObjectType
                projectId
                isDisplayColumn
                standardValidations {
                  name
                  configObjectId
                  configObjectType
                  mode
                  defaultErrorMessage
                  validationType
                  regex
                  isConditionAvailable
                  conditionExpression
                  conditionFields
                  description
                }
              }
              fontSize
              hasSupportingLabel
              supportingLabel
              supportingLabelFontSize
              labelColor
            }
          }
        }
        accessibilityRegex
        order
        expressionAvailable
        name
        configObjectType
        createdBy
        isDeleted
        itemDescription
        insert_ts
        projectId
        updatedBy
        update_ts
        deletionDate
      }
      projectId
      entity {
        name
        configObjectId
        configObjectType
        projectId
        dbTypeName
        logicalColumns {
          isPrimaryKey
          dataType
          isVerticalFieldKey
          dbCode
          length
          dbType
          isMandatory
          jsonName
          mode
          isUnique
          isDerived
          configObjectId
          name
          configObjectType
          projectId
          isDisplayColumn
          standardValidations {
            name
            configObjectId
            configObjectType
            mode
            defaultErrorMessage
            validationType
            regex
            isConditionAvailable
            conditionExpression
            conditionFields
            description
          }
        }
      }
      insertForm {
        defaultDataString
        isRepeatable
        addAllowed
        filterFormReloadControl
        isDeletionAllowed
        tabRequiredInFormsection
        tab
        columnDataPreprocessors {
          configObjectId
          name
          configObjectType
          preProcessorBean
          isMultiValue
          excecutionType
          jsCode
          createdBy
          isDeleted
          itemDescription
          insert_ts
          projectId
          updatedBy
          update_ts
          deletionDate
          actionFlows {
            configObjectId
            name
            configObjectType
          }
        }
        order
        formType
        buttonPanels {
          configObjectId
          defaultType
          buttonPanelPosition
          mode
          name
          projectId
          configObjectType
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
            createdBy
            isDeleted
            insert_ts
            updatedBy
            update_ts
            deletionDate
          }
          menuButtons {
            defaultType
            menuLabel
            position
            buttonClass
            warningMessage
            order
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            transactionName
            modalRequired
            portalId
            configObjectId
            name
            configObjectType
            childRelations {
              relationType
              parentItemId
              childItemId
            }
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
            }
            buttons {
              label
              order
              buttonClass
              warningMessage
              portalId
              buttonAlignment
              accessibilityRegex
              editabilityRegex
              expressionAvailable
              configObjectId
              name
              configObjectType
              projectId
              modalCss
              modalRequired
              transactionName
              childRelations {
                relationType
                parentItemId
                childItemId
              }
              privileges {
                privilegeId
                privilegeType
                itemId
                roleId
              }
            }
          }
          buttons {
            label
            order
            dbCode
            toolTip
            buttonStyle
            icon
            modalRequired
            type
            componentId
            buttonClass
            warningMessage
            portalId
            buttonAlignment
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            configObjectId
            name
            configObjectType
            projectId
            modalCss
            transactionName
            childRelations {
              relationType
              parentItemId
              childItemId
            }
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
              createdBy
              isDeleted
              insert_ts
              updatedBy
              update_ts
              deletionDate
            }
          }
        }
        expressionAvailable
        accessibilityRegex
        editabilityRegex
        formLabel
        maxRepeatation
        minRepeatation
        defaultRepeatation
        repeatationStyle
        isDeletionAllowed
        configObjectId
        name
        configObjectType
        projectId
        privileges {
          privilegeId
          privilegeType
          itemId
          roleId
          createdBy
          isDeleted
          insert_ts
          updatedBy
          update_ts
          deletionDate
        }
        formSections {
          configObjectId
          headerLabel
          order
          displayName
          accessibilityRegex
          editabilityRegex
          expressionAvailable
          tabGroup
          requiredFormfields
          componentsPerRow
          name
          configObjectType
          projectId
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
            createdBy
            isDeleted
            insert_ts
            updatedBy
            update_ts
            deletionDate
          }
          formFields {
            mandatoryIconAccessibilityRegex
            height
            editorType
            entityName
            parentDBCode
            layout
            dbCode
            dbCode
            label
            label
            defaultValue
            colspan
            isButtonTextBox
            formatDate
            isApplyDateFormat
            columnDataPreprocessors {
              configObjectId
              name
              configObjectType
              preProcessorBean
              isMultiValue
              excecutionType
              jsCode
              createdBy
              isDeleted
              itemDescription
              insert_ts
              projectId
              updatedBy
              update_ts
              deletionDate
              actionFlows {
                configObjectId
                name
                configObjectType
              }
            }
            order
            type
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
              createdBy
              isDeleted
              insert_ts
              updatedBy
              update_ts
              deletionDate
            }
            multivalueList
            resetOnRefresh
            isMandatory
            selectItemsReferenceID
            isAsyncOption
            placeHolder
            expressionAvailable
            refreshFormOnChange
            accessibilityRegex
            editabilityRegex
            previewAccessibilityRegex
            previewEditabilityRegex
            deleteAccessibilityRegex
            deleteEditabilityRegex
            acceptedFileTypes
            maxFilesAllowed
            isReorderAllowed
            configObjectId
            name
            configObjectType
            itemDescription
            projectId
            showYearDropdown
            showMonthDropdown
            maxDate
            minDate
            dropdownMode
            timeFormat
            showTimeSelect
            showTimeSelectOnly
            timeCaption
            timeIntervals
            isClearable
            buttons {
              label
              order
              type
              dbCode
              toolTip
              buttonStyle
              icon
              modalRequired
              componentId
              buttonClass
              warningMessage
              portalId
              buttonAlignment
              accessibilityRegex
              editabilityRegex
              expressionAvailable
              configObjectId
              name
              configObjectType
              projectId
              modalCss
              transactionName
              childRelations {
                relationType
                parentItemId
                childItemId
              }
              privileges {
                privilegeId
                privilegeType
                itemId
                roleId
              }
            }
            logicalColumn {
              isPrimaryKey
              dataType
              isVerticalFieldKey
              dbCode
              length
              dbType
              isMandatory
              jsonName
              mode
              isUnique
              isDerived
              configObjectId
              name
              configObjectType
              projectId
              isDisplayColumn
              standardValidations {
                name
                configObjectId
                configObjectType
                mode
                defaultErrorMessage
                validationType
                regex
                isConditionAvailable
                conditionExpression
                conditionFields
                description
              }
            }
            fontSize
            hasSupportingLabel
            supportingLabel
            supportingLabelFontSize
            labelColor
          }
        }
      }
      editForm {
        defaultDataString
        isRepeatable
        addAllowed
        filterFormReloadControl
        isDeletionAllowed
        tabRequiredInFormsection
        columnDataPreprocessors {
          configObjectId
          name
          configObjectType
          preProcessorBean
          isMultiValue
          excecutionType
          jsCode
          createdBy
          isDeleted
          itemDescription
          insert_ts
          projectId
          updatedBy
          update_ts
          deletionDate
          actionFlows {
            configObjectId
            name
            configObjectType
          }
        }
        tab
        order
        formType
        buttonPanels {
          configObjectId
          defaultType
          buttonPanelPosition
          mode
          name
          projectId
          configObjectType
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
            createdBy
            isDeleted
            insert_ts
            updatedBy
            update_ts
            deletionDate
          }
          menuButtons {
            defaultType
            menuLabel
            position
            buttonClass
            warningMessage
            order
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            transactionName
            modalRequired
            portalId
            configObjectId
            name
            configObjectType
            childRelations {
              relationType
              parentItemId
              childItemId
            }
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
            }
            buttons {
              label
              order
              buttonClass
              warningMessage
              portalId
              buttonAlignment
              accessibilityRegex
              editabilityRegex
              expressionAvailable
              configObjectId
              name
              configObjectType
              projectId
              modalCss
              modalRequired
              transactionName
              childRelations {
                relationType
                parentItemId
                childItemId
              }
              privileges {
                privilegeId
                privilegeType
                itemId
                roleId
              }
            }
          }
          buttons {
            label
            order
            dbCode
            toolTip
            buttonStyle
            icon
            modalRequired
            type
            componentId
            buttonClass
            warningMessage
            portalId
            buttonAlignment
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            configObjectId
            name
            configObjectType
            projectId
            modalCss
            transactionName
            childRelations {
              relationType
              parentItemId
              childItemId
            }
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
              createdBy
              isDeleted
              insert_ts
              updatedBy
              update_ts
              deletionDate
            }
          }
        }
        expressionAvailable
        accessibilityRegex
        editabilityRegex
        formLabel
        maxRepeatation
        minRepeatation
        defaultRepeatation
        repeatationStyle
        isDeletionAllowed
        privileges {
          privilegeId
          privilegeType
          itemId
          roleId
          createdBy
          isDeleted
          insert_ts
          updatedBy
          update_ts
          deletionDate
        }
        configObjectId
        name
        configObjectType
        itemDescription
        projectId
        formSections {
          configObjectId
          headerLabel
          order
          displayName
          accessibilityRegex
          editabilityRegex
          expressionAvailable
          tabGroup
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
            createdBy
            isDeleted
            insert_ts
            updatedBy
            update_ts
            deletionDate
          }
          requiredFormfields
          componentsPerRow
          name
          configObjectType
          itemDescription
          projectId
          formFields {
            mandatoryIconAccessibilityRegex
            height
            editorType
            entityName
            parentDBCode
            layout
            label
            label
            defaultValue
            colspan
            formatDate
            isApplyDateFormat
            isButtonTextBox
            columnDataPreprocessors {
              configObjectId
              name
              configObjectType
              preProcessorBean
              isMultiValue
              excecutionType
              jsCode
              createdBy
              isDeleted
              itemDescription
              insert_ts
              projectId
              updatedBy
              update_ts
              deletionDate
              actionFlows {
                configObjectId
                name
                configObjectType
              }
            }
            order
            type
            multivalueList
            resetOnRefresh
            selectItemsReferenceID
            isAsyncOption
            placeHolder
            isMandatory
            expressionAvailable
            refreshFormOnChange
            accessibilityRegex
            editabilityRegex
            previewAccessibilityRegex
            previewEditabilityRegex
            deleteAccessibilityRegex
            deleteEditabilityRegex
            acceptedFileTypes
            maxFilesAllowed
            isReorderAllowed
            configObjectId
            showYearDropdown
            showMonthDropdown
            maxDate
            minDate
            dropdownMode
            timeFormat
            showTimeSelect
            showTimeSelectOnly
            timeCaption
            timeIntervals
            isClearable
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
              createdBy
              isDeleted
              insert_ts
              updatedBy
              update_ts
              deletionDate
            }
            name
            configObjectType
            itemDescription
            projectId
            buttons {
              label
              order
              type
              dbCode
              toolTip
              buttonStyle
              icon
              modalRequired
              componentId
              buttonClass
              warningMessage
              portalId
              buttonAlignment
              accessibilityRegex
              editabilityRegex
              expressionAvailable
              configObjectId
              name
              configObjectType
              projectId
              modalCss
              transactionName
              childRelations {
                relationType
                parentItemId
                childItemId
              }
              privileges {
                privilegeId
                privilegeType
                itemId
                roleId
              }
            }
            logicalColumn {
              isPrimaryKey
              dataType
              isVerticalFieldKey
              dbCode
              length
              dbType
              isMandatory
              jsonName
              mode
              isUnique
              isDerived
              configObjectId
              name
              configObjectType
              projectId
              isDisplayColumn
              standardValidations {
                name
                configObjectId
                configObjectType
                mode
                defaultErrorMessage
                validationType
                regex
                isConditionAvailable
                conditionExpression
                conditionFields
                description
              }
            }
            fontSize
            hasSupportingLabel
            supportingLabel
            supportingLabelFontSize
            labelColor
          }
        }
      }
      dataGrid {
        configObjectId
        defaultOrdering
        gridType
        isHavingAdvanceFilterForm
        label
        swimlaneRequired
        modalRequired
        isRowReOrder
        isBorderEnable
        isStrippedEnable
        isHoverEnable
        isScrollEnable
        isServerPaginationEnable
        isRowSelectionEnable
        isHeaderAvailable
        isSubComponentEnable
        subComponentType
        isEditButtonEnable
        defaultSorting
        gridDataAlignment
        gridHeaderAlignment
        privileges {
          privilegeId
          privilegeType
          itemId
          roleId
          createdBy
          isDeleted
          insert_ts
          updatedBy
          update_ts
          deletionDate
        }
        name
        configObjectType
        projectId
        itemDescription
        buttonPanels {
          configObjectId
          defaultType
          buttonPanelPosition
          mode
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
            createdBy
            isDeleted
            insert_ts
            updatedBy
            update_ts
            deletionDate
          }
          name
          configObjectType
          itemDescription
          projectId
          menuButtons {
            defaultType
            menuLabel
            position
            buttonClass
            warningMessage
            order
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            transactionName
            modalRequired
            portalId
            configObjectId
            name
            configObjectType
            childRelations {
              relationType
              parentItemId
              childItemId
            }
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
            }
            buttons {
              label
              order
              buttonClass
              warningMessage
              portalId
              buttonAlignment
              accessibilityRegex
              editabilityRegex
              expressionAvailable
              configObjectId
              name
              configObjectType
              projectId
              modalCss
              modalRequired
              transactionName
              childRelations {
                relationType
                parentItemId
                childItemId
              }
              privileges {
                privilegeId
                privilegeType
                itemId
                roleId
              }
            }
          }
          buttons {
            label
            order
            dbCode
            toolTip
            buttonStyle
            icon
            modalRequired
            type
            componentId
            buttonClass
            warningMessage
            portalId
            buttonAlignment
            modalCss
            transactionName
            childRelations {
              relationType
              parentItemId
              childItemId
            }
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
              createdBy
              isDeleted
              insert_ts
              updatedBy
              update_ts
              deletionDate
            }
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            configObjectId
            name
            configObjectType
            projectId
          }
        }
        form {
          configObjectId
          expressionAvailable
          accessibilityRegex
          editabilityRegex
          logicalEntity {
            name
            configObjectId
            configObjectType
            createdBy
            isDeleted
            itemDescription
            insert_ts
            projectId
            updatedBy
            update_ts
            deletionDate
            dbTypeName
            supportedFlavor
            generateSkeleton
            logicalColumns {
              isPrimaryKey
              isDisplayColumn
              dataType
              isVerticalFieldKey
              dbCode
              length
              dbType
              isMandatory
              jsonName
              mode
              isUnique
              isDerived
              configObjectId
              name
              configObjectType
              createdBy
              isDeleted
              itemDescription
              insert_ts
              projectId
              updatedBy
              update_ts
              deletionDate
            }
          }

          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
            createdBy
            isDeleted
            insert_ts
            updatedBy
            update_ts
            deletionDate
          }
        }
        dataGridColumns {
          headerName
          order
          hyperlinkDBCode
          modalCss
          visible
          dateFormat
          configObjectId
          name
          configObjectType
          accessibilityRegex
          dbCode
          filterEnable
          filterType
          columnType
          isDisplayDetail
          isPrimaryKey
          editabilityRegex
          expressionAvailable
          itemDescription
          projectId
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
            createdBy
            isDeleted
            insert_ts
            updatedBy
            update_ts
            deletionDate
          }
          toolTip
          hyperLink
          hrefValue
          actionColumn
          actionColumnType
          group
          icon
          width
          isEllipsesEnable
          isDefaultEditable
          isShowOnlyOnEdit
          isAuditColumnJson
          collapseIcon
          auditColumnName
          columnDataAlignment
          columnHeaderAlignment
          isTimeStamp
          datasourceId
          multivalueList
          selectItemsReferenceID
          logicalColumn {
            isPrimaryKey
            isDisplayColumn
            dataType
            isVerticalFieldKey
            dbCode
            length
            dbType
            isMandatory
            jsonName
            mode
            isUnique
            isDerived
            configObjectId
            name
            configObjectType
            createdBy
            isDeleted
            itemDescription
            insert_ts
            projectId
            updatedBy
            update_ts
            deletionDate
          }
          formField {
            label
            order
            height
            type
            displayType
            editorType
            isMandatory
            isButtonTextBox
            expressionAvailable
            refreshFormOnChange
            accessibilityRegex
            editabilityRegex
            previewAccessibilityRegex
            previewEditabilityRegex
            deleteAccessibilityRegex
            deleteEditabilityRegex
            acceptedFileTypes
            maxFilesAllowed
            isReorderAllowed
            expressionFieldString
            configObjectId
            name
            configObjectType
            createdBy
            isDeleted
            itemDescription
            insert_ts
            projectId
            updatedBy
            update_ts
            deletionDate
            multivalueList
            selectItemsReferenceID
            isAsyncOption
            placeHolder
            dataSourceName
            defaultValue
            isMultiLingual
            formatDate
            isApplyDateFormat
            resetOnRefresh
            rowspan
            colspan
            hasToolTipIcon
            toolTipText
            isSearchBoxRequired
            isHyperlink
            gotoLink
            uploadLabel
            outDbcodeUploadFileTempId
            outDbcodeUploadFileName
            outDbcodeUploadFileType
            isRefreshFormWithUploadData
            valueType
            isReadOnly
            starCount
            starColor
            mask
            maskChar
            isSearchable
            isDisabled
            isMulti
            showYearDropdown
            showMonthDropdown
            maxDate
            minDate
            dateFormat
            dropdownMode
            timeFormat
            showTimeSelect
            showTimeSelectOnly
            timeIntervals
            timeCaption
            entityName
            parentDBCode
            layout
            dbCode
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
              createdBy
              isDeleted
              insert_ts
              updatedBy
              update_ts
              deletionDate
            }
            buttons {
              label
              order
              type
              dbCode
              toolTip
              buttonStyle
              icon
              modalRequired
              componentId
              buttonClass
              warningMessage
              portalId
              buttonAlignment
              accessibilityRegex
              editabilityRegex
              expressionAvailable
              configObjectId
              name
              configObjectType
              projectId
              modalCss
              transactionName
              childRelations {
                relationType
                parentItemId
                childItemId
              }
              privileges {
                privilegeId
                privilegeType
                itemId
                roleId
              }
            }
            logicalColumn {
              isPrimaryKey
              isDisplayColumn
              dataType
              isVerticalFieldKey
              dbCode
              length
              dbType
              isMandatory
              jsonName
              mode
              isUnique
              isDerived
              configObjectId
              name
              configObjectType
              createdBy
              isDeleted
              itemDescription
              insert_ts
              projectId
              updatedBy
              update_ts
              deletionDate
            }
            fontSize
            hasSupportingLabel
            supportingLabel
            supportingLabelFontSize
            labelColor
          }
        }
        actionDataGridColumns {
          headerName
          order
          hrefValue
          visible
          configObjectId
          name
          configObjectType
          projectId
          toolTip
          actionColumn
          actionColumnType
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
            createdBy
            isDeleted
            insert_ts
            updatedBy
            update_ts
            deletionDate
          }
          group
          icon
          width
        }
      }
    }
  }
`;
